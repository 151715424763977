import { Component, Input, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/_common/customvalidators';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';


@Component({
	selector: 'app-credit-confirmation',
	templateUrl: './credit-confirmation.component.html',
	styleUrls: ['./credit-confirmation.component.scss'],
})
export class CreditConfirmationComponent implements OnInit {

	@Input() orderData;
	public subscription = new Subscription();
	showLoader: boolean = false;
	creditStatus: any;
	public rejectForm: FormGroup;
	public validation_messages = {
		reason_reject: [
			{ type: 'required', message: 'creditComponent.reasonRequired' },
			{ type: 'invalidString', message: 'creditComponent.invalidReason' },
			{ type: 'minlength', message: 'creditComponent.reasonMinLength' },
			{ type: 'minlength', message: 'creditComponent.reasonMaxLength' },
		],
	}

	constructor(private modalCtrl: ModalController,
		private translate: TranslateService,
		public commonService: CommonService,
		private fb: FormBuilder,
		private dataService: ApiService,
		private toastService: ToastService,) { }

	ngOnInit() {
		console.log(this.orderData)
	}

	dissmis() {
		this.modalCtrl.dismiss();
	}

	async statusChangeAPICall(invoiceid, status) {
		if (status == 'APPROVED') {
			this.creditStatus = 'APPROVED';
		}
		if (status == 'REJECTED') {
			this.creditStatus = 'REJECTED';
			this.rejectForm = this.initializeForm();
		}
	}

	get f() {
		return this.rejectForm.controls;
	}

	submitRejectCreditOrder() {
		for (const i in this.f) {
			this.f[i].markAsDirty();
			this.f[i].updateValueAndValidity();
			this.f[i].markAsTouched();
		}

		if (this.rejectForm.invalid) {
			this.toastService.displayToast(this.translate.instant('creditComponent.invalidReason'), null, 'danger');
			return;

		}

		this.showLoader = true;
		let params = {
			"invoice_id": this.orderData.invoice_id,
			"credit_approval_status": 'REJECTED',
			"rejection_reason": this.f.reason_reject.value
		}
		this.subscription.add(
			this.dataService.post(URLS.UpdateCreditStatus, params).subscribe(data => {
				if (data['code'] == 200) {
					this.modalCtrl.dismiss({ data: true });
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
				setTimeout(() => { this.showLoader = false; }, 300);
			}, error => {
				setTimeout(() => { this.showLoader = false; }, 300);
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	acceptCreditOrder() {
		let params = {
			"invoice_id": this.orderData.invoice_id,
			"credit_approval_status": 'APPROVED',
		}
		this.showLoader = true;

		this.subscription.add(
			this.dataService.post(URLS.UpdateCreditStatus, params).subscribe(data => {
				if (data['code'] == 200) {
					this.toastService.displayToast(data['message'], null, 'success');
					this.modalCtrl.dismiss({ data: true });
					setTimeout(() => { this.showLoader = false; }, 300);
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
					setTimeout(() => { this.showLoader = false; }, 300);
				}

			}, error => {
				setTimeout(() => { this.showLoader = false; }, 300);

				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}


	initializeForm(data = null) {
		return this.fb.group({
			reason_reject: new FormControl(
				data && data.name ? data.name : null,
				{
					validators: Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(1200), CustomValidator.noWhiteSpaceValidator]),
				}
			),
		})
	}
}
